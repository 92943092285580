import {SLIDER_CONSTS} from "../constants";

export const initialState = {
    mainSlider: [],
    aboutSlider: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SLIDER_CONSTS.GET_MAIN_SLIDER:

            return {
                ...state,
                mainSlider: action.payload,
            };

        case SLIDER_CONSTS.GET_ABOUT_SLIDER:
            return {
                ...state,
                aboutSlider: action.payload,
            };

        default:
            return state;
    }
}
