// Import packages
import React from "react";
import {useParams} from 'react-router-dom';

// Import assets
import "./stories.scss"

import {BUILDINGS_360_LINKS} from "../constants/constants";

function Stories360 () {
    const params = useParams()

    return <div className={'Stories'}>
        <div className={"iframe-wrapper"}>
            <iframe src={BUILDINGS_360_LINKS?.[params?.project]?.[params?.floor]}
                    frameBorder={"0px"}
            />
        </div>
    </div>
}

export default Stories360;
