// import packages
import React, {useState, useEffect, Fragment} from "react";
import {connect} from "react-redux";
import MouseTooltip from 'react-sticky-mouse-tooltip';
import {Breadcrumb} from 'antd';
import {history} from "../../configs/history";
import {useLocation, useParams} from 'react-router-dom';
import {Link} from "react-router-dom";

// Import assets
import "./floor-details.scss"
import {FilterIcon, img_360, ParkingIcon} from "../../assets/images";

// Import Components
import MetaTags from "../../components/MetaTags";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetFloorById, GetBuildingByIdOrSlug, ClearBuildingByIdOrSlug, ViewHouses} from "../../redux/actions";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";
import {
    AREAS_SORT_TYPES, BUILDINGS_360_LINKS,
    BUILDINGS_KEYS,
    floorsImages,
    floorsSelect,
    HOUSE_BADGES,
    PARKING_COUNTS,
    PROJECTS_CONSTS,
    SOLD_IMAGES
} from "../../constants/constants";
import {ACCEPT_BADGE_TYPES} from "../../constants/acceptedConsts";
import {AREA_TYPES} from "../../constants/constType";

function FloorDetails(props) {
    const [isHouseInfoVisible, setHouseInfoVisible] = useState(false)
    const [housesInfo, setHousesInfo] = useState({})
    const [floor, setFloor] = useState({})
    const params = useParams()
    const location = useLocation()
    const lastFloor = housesInfo?.areaNumber?.slice(0)
    const {buildingById, badges, staticTexts, selectedLanguage, projects} = props
    let floorId = ''

    useEffect(async () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        const buildingData = params.building && await props.GetBuildingByIdOrSlug(params.building)
        let floorId = buildingData?.floors?.length && buildingData?.floors?.find(item => item.number === parseInt(params.num))?.id
        // floorId = floorId || buildingData?.floors[buildingData?.floors?.length-1]?.id

        if (Object.keys(PARKING_COUNTS).includes(floorId)) {
            floorId = PARKING_COUNTS[floorId]
        }
        buildingData && props.GetFloorById(floorId).then((floor) => {
            if (floor.isPenthouse && !params.num?.includes('_')) {
                history.replace(location.pathname + '_1')
            }
            setFloor(floor);

            const items = floor?.areas;
            items && items?.length && items?.map(item => {
                // const link =(item?.type === AREA_TYPES.PARKING.key) ?
                //     getUrlWithLocal(`/building/${params.building}/parking/floor/${params?.num}/place/${item?.id}`):
                //     getUrlWithLocal(`/building/${params.building}/floor/${params?.num}/house/${item?.id}`)

                if (item?.type === AREA_TYPES.APARTMENT.key && document.getElementById(item?.position) || params?.building === 'A' || params?.building === 'B') {

                    // const listItem = document.getElementById(item?.position)
                    // listItem.parentNode.setAttribute('href', link)

                    document.getElementById(item?.position)?.addEventListener("mouseenter", onMouseEnter.bind(null, item));
                    document.getElementById(item?.position)?.addEventListener("mouseleave", onMouseLeave.bind(null, item))
                    document.getElementById(item?.position)?.addEventListener("click", onClick.bind(null, item))

                    document.getElementById(item?.position).style.stroke = item?.badge?.color;
                    document.getElementById(item?.position).style.fill = item?.badge?.color;
                }
                // console.log(item?.areaNumber, 'artgroupadmin1@info.com');
                if (document.getElementById(item?.areaNumber)) {
                    document.getElementById(item?.areaNumber)?.addEventListener("mouseenter", onMouseEnter.bind(null, item));
                    document.getElementById(item?.areaNumber)?.addEventListener("mouseleave", onMouseLeave.bind(null, item))
                    document.getElementById(item?.areaNumber)?.addEventListener("click", onClick.bind(null, item))
                    document.getElementById(item?.areaNumber).style.stroke = item?.badge?.color;
                    document.getElementById(item?.areaNumber).style.fill = item?.badge?.color;

                    if (isParking) {
                        if (item?.badge?.id === ACCEPT_BADGE_TYPES?.FOR_SALE) {
                            document.getElementById(item?.areaNumber).style.stroke = item?.badge?.color;
                            document.getElementById(item?.areaNumber).style.fill = item?.badge?.color;
                        }
                    }
                }
            })
        })
    }, [params.num])


    useEffect(() => {
        return () => {
            setFloor({});
            props.ClearBuildingByIdOrSlug()
        }
    }, [])

    useEffect(() => {
        setHouseInfoVisible(false)
    }, [location.pathname])

    function onMouseEnter(house) {
        setHouseInfoVisible(true);
        setHousesInfo(house);
    }

    function onMouseLeave() {
        setHouseInfoVisible(false)
        setHousesInfo({})
    }

    function onClick(house) {
        props.ViewHouses(house.id)
    }

    function onClickFloor(floor, number) {
        // console.log('floor, number',buildingById, floor, number)
        floorId = floor.id;
        !floor?.inactive && floor?.id && props.GetFloorById(floorId).then((data) => {
            // if (floor?.number > 0) {
            //     if (!floor.isPenthouse) {
            //         history.push(getUrlWithLocal(`/building/${params.building}/floor/${data?.number}`))
            //     } else {
            //         history.push(getUrlWithLocal(`/building/${params.building}/floor/${number}`))
            //     }
            // } else {
            //     history.push(getUrlWithLocal(`/building/${params.building}/parking/floor/${data?.number}`))
            // }
            if (!floor.isPenthouse) {
                const isParking = data.number <= Number(PROJECTS_CONSTS[buildingById?.project?.slug]?.parking_first_floor)
                history.push(getUrlWithLocal(`/building/${params.building}${isParking ? "/parking" : ''}/floor/${data?.number}`))
            } else {
                history.push(getUrlWithLocal(`/building/${params.building}/floor/${number}`))
            }

            setFloor(data);
        })
    }

    function buildingFloorsListJSX() {
        return <div className={'floors-list'}>
            {!!props.buildingById?.floors?.length &&
            props.buildingById?.floors?.slice(PROJECTS_CONSTS?.[props?.buildingById?.project?.slug]?.parking_count).map(item => {
                return !item.isPenthouse ? <div onClick={() => onClickFloor(item)}
                                                key={item?.id}
                                                className={`list-item ${item.number === floor?.number ? 'active' : (item.inactive ? 'inactive-floor' : '')}`}>{item?.number}</div>
                    : <Fragment key={item?.id}>
                        <div onClick={() => onClickFloor(item, `${item?.number}_1`)}
                             className={`list-item ${`${item?.number}_1` === params?.num ? 'active' : (item.inactive ? 'inactive-floor' : '')}`}>{item?.number}-1
                        </div>
                        <div onClick={() => onClickFloor(item, `${item?.number}_2`)}
                             className={`list-item ${`${item?.number}_2` === params?.num ? 'active' : (item.inactive ? 'inactive-floor' : '')}`}>{item?.number}-2
                        </div>
                    </Fragment>
            })}
        </div>
    }

    function buildingParkingListJSX() {
        return <div className="parking-floors-list">
            {!!buildingById?.floors?.length &&
            buildingById?.floors?.slice(0, PROJECTS_CONSTS?.[props?.buildingById?.project?.slug]?.parking_count).reverse().map(item => {
                return <div onClick={() => onClickFloor(item)}
                            key={item?.id}
                            className={`list-item ${item.number === floor?.number ? 'active' : (item.inactive ? 'inactive-floor' : '')}`}>{item?.number}</div>
            })}
        </div>
    }

    // function onClick (house) {
    //     if (house?.type === AREA_TYPES.PARKING.key) {
    //         window.open(getUrlWithLocal(`/building/${params.building}/parking/floor/${params?.num}/place/${house?.id}`), '_blank');
    //     } else {
    //         window.open(getUrlWithLocal(`/building/${params.building}/floor/${params?.num}/house/${house?.id}`), '_blank');
    //     }
    // }

    const Svg = floorsSelect[`${params?.building}_${params?.num}`]
    const isParking = Number(params?.num) <= PROJECTS_CONSTS[buildingById?.project?.slug]?.parking_first_floor

    const project = props?.projects?.find(pr => pr.id === floor?.project?.id)
    const hoveredAreaViewed = housesInfo && props?.viewedHouses?.includes(housesInfo?.id) || false
    const areaDetailsLink = housesInfo ? ((housesInfo?.type === AREA_TYPES.PARKING.key) ?
        getUrlWithLocal(`/building/${params?.building}/parking/floor/${params?.num}/place/${housesInfo?.id}`) :
        getUrlWithLocal(`/building/${params?.building}/floor/${params?.num}/house/${housesInfo?.id}`)) : ''
    return <div className={'FloorWrapper'}>
        <MetaTags
            title={`${staticTexts?.rout_item_building} ${BUILDINGS_KEYS[buildingById?.slug]} ${staticTexts?.rout_item_floor} ${floor?.number}`}
            description={`${project?.title}\` ${project?.address}`}
        />
        <div className={'ContentWrapper'}>
            <div className={'HeaderWrapper'}>
                <div className={'header-left-wrapper'}>
                    <span
                        className={'header-title'}>{`${staticTexts?.rout_item_title_floor} ${floor?.number ? floor?.number : ""}`}</span>
                    {
                        params?.building === PROJECTS_CONSTS.Park.building_name ?
                            <Breadcrumb>
                                <Breadcrumb.Item><Link
                                    to={getUrlWithLocal('/home')}>{staticTexts?.rout_item_home}</Link></Breadcrumb.Item>
                                <Breadcrumb.Item><Link
                                    to={getUrlWithLocal(`/buildings/${floor?.project?.slug}`)}>
                                    {projects?.map(item => {
                                        if (item?.buildings[0]?.slug === params?.building) {
                                            return item?.title
                                        }
                                    })}
                                </Link></Breadcrumb.Item>
                                {floor?.number &&
                                <Breadcrumb.Item>{`${staticTexts?.rout_item_floor} ${params?.num}`}</Breadcrumb.Item>}
                            </Breadcrumb>
                            : <Breadcrumb>
                                <Breadcrumb.Item><Link
                                    to={getUrlWithLocal('/home')}>{staticTexts?.rout_item_home}</Link></Breadcrumb.Item>
                                <Breadcrumb.Item><Link
                                    to={getUrlWithLocal(`/buildings/${floor?.project?.slug}`)}>{staticTexts?.rout_item_buildings}</Link></Breadcrumb.Item>
                                <Breadcrumb.Item><Link
                                    to={getUrlWithLocal(`/buildings/${floor?.project?.slug}`)}>{`${staticTexts?.rout_item_building} ${BUILDINGS_KEYS[buildingById?.slug]}`}</Link></Breadcrumb.Item>
                                {floor?.number &&
                                <Breadcrumb.Item>{`${staticTexts?.rout_item_floor} ${params?.num}`}</Breadcrumb.Item>}
                            </Breadcrumb>
                    }
                </div>
                {floor?.number > 0 && <Link to={{
                    pathname: getUrlWithLocal('/filter'),
                    state: {
                        building: params?.building,
                        floor: floor?.number,
                        sort: AREAS_SORT_TYPES.PRICE_UP
                    }
                }} className={'item-card'}>
                    <div className={'icon-wrapper'}>
                        <FilterIcon/>
                    </div>
                    <div className={'item-card-content'}>
                        <div className={'item-card-content-title'}>{staticTexts?.rout_item_filter_title}</div>
                        <div className={'item-card-content-text'}>{staticTexts?.rout_item_filter_text}</div>
                    </div>
                </Link>}
            </div>
            <div className={"FloorMainContent"}>
                <div className={'LeftSide'}>
                    <div className={'floors-main-wrapper'}>
                        <div className={'floors-content'}>
                            <div className={'selected-floor'}>
                                {`${staticTexts?.rout_item_floor} ${floor?.number ? floor?.number : ''}`}
                            </div>
                            {buildingFloorsListJSX()}
                        </div>
                        <div className={'parking-wrapper'}>
                            <ParkingIcon className="parking-icon" title=""/>
                            {buildingParkingListJSX()}
                        </div>
                        {/*<div className={'compass-wrapper compass-wrapper-in-left '}>*/}
                        {/*    <img src={CompassImage} alt=""/>*/}
                        {/*</div>*/}
                    </div>
                    <div className={'badges-wrapper'}>
                        {badges && !!badges?.length && badges.map(item => {
                            return <Link to={{
                                state: {statuses: [item?.id], projects: [project?.id]},
                                pathname: getUrlWithLocal("/filter")
                            }} className={'badge-item'} key={item?.id}>
                                <div className={'badge-color'} style={{background: item.color}}/>
                                <div>{item?.title}</div>
                            </Link>
                        })}
                        {/*<div className={'compass-wrapper compass-wrapper-in-right '}>*/}
                        {/*    <img src={CompassImage} alt=""/>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div
                    className={`${params?.num === '16' && params?.building === 'A' ? "RightSideLastFloor" : "RightSide"}`}>
                    {<Link onClick={(e)=>{
                        if(!housesInfo?.id){
                            e.preventDefault();
                            e.stopPropagation()
                        }
                    }}
                        to={housesInfo ? ((housesInfo?.type === AREA_TYPES.PARKING.key) ?
                        getUrlWithLocal(`/building/${params?.building}/parking/floor/${params?.num}/place/${housesInfo?.id}`) :
                        getUrlWithLocal(`/building/${params?.building}/floor/${params?.num}/house/${housesInfo?.id}`)) : ''}
                           target={window.innerWidth > 1050 ? "_blank" : "_self"}
                           className={`ImageWrapper ${isParking && params?.building === 'A' || isParking && params?.building === 'B' ? "ParkingImageWrapper" : params?.building === 'A3' ? "Image" : ''}`}>
                        {BUILDINGS_360_LINKS[buildingById?.project?.slug] && !isParking &&
                        <Link to={getUrlWithLocal(`stories-360/${buildingById?.project?.slug}/${params?.num}`)}
                              className="view-360">
                            <img src={img_360} alt=""/>
                        </Link>}
                        {
                            <img src={floorsImages[`${params?.building}_${params?.num}`]} alt={"building"}/>
                        }

                        {isParking ? !floor?.parking?.find(item => item?.badge?.id !== HOUSE_BADGES.sold.key)
                            : !!floor?.areas?.length && !floor?.areas?.find(item => item?.badge?.id !== HOUSE_BADGES.sold.key) &&
                            <img className="sold-item" src={SOLD_IMAGES?.[selectedLanguage?.code]} alt=""/>
                        }
                        {floorsImages[`${params?.building}_${params?.num}`] && <Svg/>}
                        {
                            isHouseInfoVisible && <MouseTooltip
                                style={{zIndex: "103"}}
                                visible={isHouseInfoVisible}
                                offsetX={isParking ? -60 : -115}
                                offsetY={isParking ? 20 : 30}
                            >
                                <div className={'tooltip-wrapper'}>
                                    <div className={`tooltip-left-part ${isParking ? "without-right-part" : ""}`}
                                         style={{background: housesInfo?.badge?.color}}>
                                        {params?.num === PROJECTS_CONSTS.Garun.floor_count || params?.num === PROJECTS_CONSTS.Park.floor_count ?
                                            <span className={'building-number'}>N {lastFloor}</span> :
                                            <span className={'building-number'}>N {housesInfo.areaNumber}</span>
                                        }
                                        {hoveredAreaViewed && !isParking &&
                                        <span>{staticTexts?.mouse_info_viewed}</span>}
                                    </div>
                                    {housesInfo?.type === 'PARKING' || <div className={'tooltip-right-part'}>
                                        {params?.building === PROJECTS_CONSTS.Ghapantsian.building_name ?
                                            params?.num === PROJECTS_CONSTS.Ghapantsian.office_fistFloor || params?.num === PROJECTS_CONSTS.Ghapantsian.office_floor ? ' ' :
                                                <span
                                                    className={'floors-count'}>{housesInfo?.roomsCount} {staticTexts?.mouse_info_room}</span>
                                                    ? params?.num === PROJECTS_CONSTS.Garun.office_floor || params?.num === PROJECTS_CONSTS.Garun.office_fistFloor ? ' ' :
                                                        <span
                                                            className={'floors-count'}>{housesInfo?.roomsCount} {staticTexts?.mouse_info_room}
                            </span>
                                                    : params?.num === PROJECTS_CONSTS.Park.office_floor || params?.num === PROJECTS_CONSTS.Park.office_fistFloor ? ' ' :
                                                        <span
                                                            className={'floors-count'}>{housesInfo?.roomsCount} {staticTexts?.mouse_info_room}
                            </span> : ' '
                                        }
                                        <span
                                            className={'houses-count'}>{`${housesInfo?.area} ${staticTexts?.measurement_unit_meter}`}<sup>2</sup></span>
                                    </div>
                                    }
                                </div>
                            </MouseTooltip>
                        }
                    </Link>}
                </div>
            </div>
        </div>
    </div>

}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'about',
    'buildings',
    'buildingById',
    'badges',
    'selectedLanguage',
    'projects',
    'viewedHouses',
]);

const mapDispatchToProps = {
    GetFloorById,
    GetBuildingByIdOrSlug,
    ClearBuildingByIdOrSlug,
    ViewHouses
};

export default connect(mapStateToProps, mapDispatchToProps)(FloorDetails);
