import {_urlBuildings, _urlFloors, _urlHouses, _urlParking, _urlReservation, request} from "../api";
import {BUILDINGS_CONSTS, UTIL_CONSTS} from "../constants";


export const GetBuildings = () => {
    const requestData = {
        url: `${_urlBuildings}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                dispatch({
                    type: BUILDINGS_CONSTS.GET_BUILDINGS,
                    payload: data
                })
                return data
            })
    };
};

export const GetBuildingByIdOrSlug = (id) => {
    const requestData = {
        url: `${_urlBuildings}/${id}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                dispatch({
                    type: BUILDINGS_CONSTS.GET_BUILDING_BY_ID,
                    payload: data
                })
                return data
            })
    };
};

export const GetBuildingData = (id) => {
    const requestData = {
        url: `${_urlBuildings}/${id}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
        return request(requestData)
            .then(({data}) => data)

};

export const ClearBuildingByIdOrSlug = () => {
    return dispatch => {
        dispatch({
            type: BUILDINGS_CONSTS.CLEAR_BUILDING_BY_ID,
        })
    };
};

export const GetFloorById = (id) => {
    const requestData = {
        url: `${_urlFloors}/${id}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    return () => {
        return request(requestData)
            .then((res) => {
                return res && res.data
            })
    };
};
export const GetHouseById = (id) => {
    const requestData = {
        url: `${_urlHouses}/${id}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    return () => {
        return request(requestData)
            .then((res) => {
                return  res && res.data
            })
    };
};
export const GetParkingById = (id) => {
    const requestData = {
        url: `${_urlParking}/${id}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    return () => {
        return request(requestData)
            .then((res) => {
                return  res && res.data
            })
    };
};
export const ReserveHouse = (data) => {
    const requestData = {
        url: `${_urlReservation}`,
        method: "POST",
        data
    };
    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            dispatch({type: UTIL_CONSTS.END_LOADING})
        })
    }
};


export const GetHouses = ({
                              offset = 0,
                              limit = 20,
                              reset = true,
                              buildingSlug = null,
                              floors = [],
                              roomsCount = 0,
                              minArea = '',
                              maxArea = '',
                              minPrice = '',
                              maxPrice = '',
                              statuses = [],
                              sort = 'price',
                          } = {}) => {

    const requestData = {
        url: `${_urlHouses}?offset=${offset}&limit=${limit}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };
    buildingSlug && (requestData.url += `&buildingSlug=${buildingSlug}`);
    floors && floors.length && (requestData.url += `&floors=${floors.join(',')}`);
    roomsCount && (requestData.url += `&roomsCount=${roomsCount}`);
    minArea && (requestData.url += `&minArea=${minArea}`);
    maxArea && (requestData.url += `&maxArea=${maxArea}`);
    minPrice && (requestData.url += `&minPrice=${minPrice}`);
    maxPrice && (requestData.url += `&maxPrice=${maxPrice}`);
    sort && (requestData.url += `&sort=${sort}`);
    statuses?.length && (requestData.url += `&statuses=${statuses?.join(",")}`);

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;

                data && dispatch({
                    type: BUILDINGS_CONSTS.GET_HOUSES,
                    payload: {
                        data: data?.houses,
                        hasMore: data?.houses?.length === limit,
                        availableCount: data?.availableCount,
                        reservedCount: data?.reservedCount,
                        reset,
                        buildingSlug,
                        floors,
                        roomsCount,
                        minArea,
                        maxArea,
                        minPrice,
                        maxPrice,
                        sort,
                        statuses,
                    }
                })
            })
    };
};



export const ViewHouses = (data) => {
    return dispatch => {
        dispatch({
            type: BUILDINGS_CONSTS.VIEW_HOUSE,
            payload: data
        })
    };
}