// Import packages
import React, {useEffect} from "react";
import {connect} from 'react-redux';

// Import styles
import './not-found.scss';

// Import components

//import utils
import {getPropsFromState} from "../../redux/mapStateToProps";

function NotFound(props) {
    useEffect(  () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    })

    return <section className='NotFoundWrapper'>
        Page Not Found
    </section>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'contact',
]);

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
