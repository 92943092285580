import {store} from "../store";
import {
    _urlAreas,
    request
} from "../api";
import {AREAS_CONSTS} from "../constants";
import {AREAS_SORT_TYPES, AVAILABLE_BADGE_ID} from "../../constants/constants";
import { AREA_TYPES } from "../../constants/constType";

export const GetAreasInitialInfo = ({
                                        types = [AREA_TYPES.APARTMENT?.key],
                                        statuses = [AVAILABLE_BADGE_ID],
                                    } = {}) => {
    const requestData = {
        url: `${_urlAreas}/info?`,
        method: "GET",
        languageFlag: true
    };
    types?.length && (requestData.url += `&types=${types}`);
    statuses?.length && (requestData.url += `&statuses=${statuses?.join(",")}`);
    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: AREAS_CONSTS.GET_AREAS_INITIAL_INFO,
                    payload: data
                })
            })
    };
};

export const GetAreasInfo = ({
                                 offset = 0,
                                 types = null,
                                 roomsCount ='',
                                 minPrice = '',
                                 maxPrice = '',
                                 projects = null,
                                 statuses = [AVAILABLE_BADGE_ID],
                             } = {}) => {
    const requestData = {
        url: `${_urlAreas}/info?offset=${offset}`,
        method: "GET",
        languageFlag: true
    };
    roomsCount?.length && (requestData.url += `&roomsCount=${roomsCount?.join(",")}`);
    types?.length && (requestData.url += `&types=${types}`);
    minPrice && (minPrice !== store?.getState()?.areas?.initialAreaData?.minPrice)
    && (requestData.url += `&minPrice=${minPrice}`);
    maxPrice && maxPrice < store?.getState()?.areas?.initialAreaData?.maxPrice
    && (requestData.url += `&maxPrice=${maxPrice}`);
    projects?.length && (requestData.url += `&projects=${projects?.join(",")}`);
    statuses?.length && (requestData.url += `&statuses=${statuses?.join(",")}`);

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: AREAS_CONSTS.GET_AREAS_INFO,
                    payload: data
                })
            })
    };
};

export const ResetAreasInfo = () => {
    store.dispatch({
        type: AREAS_CONSTS.RESET_AREAS_INFO,
    })
}


export const GetAreas = ({
                             offset = 3,
                             limit = 10,
                             reset = true,
                             buildingSlug = null,
                             projects = null,
                             types = null,
                             floors = [],
                             roomsCount = '',
                             minArea = '',
                             maxArea = '',
                             minPrice = '',
                             maxPrice = '',
                             statuses = [],
                             sort = AREAS_SORT_TYPES.PRICE_UP,
                         } = {}) => {
    const requestData = {
        url: `${_urlAreas}?offset=${offset}&limit=${limit}`,
        method: "GET",
        languageFlag: true
    };
    // console.log(offset, '---------offset');
    buildingSlug && (requestData.url += `&buildingSlug=${buildingSlug}`);
    floors && floors.length && (requestData.url += `&floors=${floors.join(',')}`);
    roomsCount?.length && (requestData.url += `&roomsCount=${roomsCount?.join(",")}`);
    types?.length && (requestData.url += `&types=${types}`);
    minArea && (requestData.url += `&minArea=${minArea}`);
    maxArea && (requestData.url += `&maxArea=${maxArea}`);
    minPrice && (minPrice !== store?.getState()?.areas?.initialAreaData?.minPrice)
    && (requestData.url += `&minPrice=${minPrice}`);
    maxPrice && maxPrice < store?.getState()?.areas?.initialAreaData?.maxPrice
    && (requestData.url += `&maxPrice=${maxPrice}`);
    sort && (requestData.url += `&sort=${sort}`);
    projects && (requestData.url += `&projects=${projects}`);
    statuses?.length && (requestData.url += `&statuses=${statuses?.join(",")}`);

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: AREAS_CONSTS.GET_AREAS,
                    payload: {data, id: projects}
                })
            })
    };
};

export const GetAreaSearch = ({
                                  offset = 0,
                                  limit = 3,
                                  reset = true,
                                  buildingSlug = null,
                                  projects = null,
                                  types = null,
                                  floors = [],
                                  roomsCount = '',
                                  minArea = '',
                                  maxArea = '',
                                  minPrice = '',
                                  maxPrice = '',
                                  statuses = [],
                                  buildings = [],
                                  sort = AREAS_SORT_TYPES.PRICE_UP,
                              } = {}) => {
    const requestData = {
        url: `${_urlAreas}/search?offset=${offset}&limit=${limit}`,
        method: "GET",
        languageFlag: true
    };

    buildingSlug && (requestData.url += `&buildingSlug=${buildingSlug}`);
    floors && floors.length && (requestData.url += `&floors=${floors.join(',')}`);
    roomsCount?.length && (requestData.url += `&roomsCount=${roomsCount?.join(",")}`);
    types?.length && (requestData.url += `&types=${types}`);
    minArea && (requestData.url += `&minArea=${minArea}`);
    maxArea && (requestData.url += `&maxArea=${maxArea}`);
    minPrice && (minPrice !== store?.getState()?.areas?.initialAreaData?.minPrice)
    && (requestData.url += `&minPrice=${minPrice}`);
    maxPrice && maxPrice < store?.getState()?.areas?.initialAreaData?.maxPrice
    && (requestData.url += `&maxPrice=${maxPrice}`);
    sort && (requestData.url += `&sort=${sort}`);
    projects?.length && (requestData.url += `&projects=${projects}`);
    statuses?.length && (requestData.url += `&statuses=${statuses?.join(",")}`);
    buildings?.length && (requestData.url += `&buildings=${buildings}`);

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                // console.log('GetAreaSearch', data);
                await dispatch({
                    type: AREAS_CONSTS.GET_AREA_SEARCH,
                    payload: {
                        projects: data?.projects,
                        hasMore: data?.projects?.length === limit,
                        availableCount: data?.availableCount,
                        reservedCount: data?.reservedCount,
                        reset,
                        buildingSlug,
                        floors,
                        roomsCount,
                        minArea,
                        maxArea,
                        minPrice,
                        maxPrice,
                        sort,
                        statuses,
                        count: data?.count
                    }
                })
            })
    };
};

export const GetAreaById = (id) => {
    const requestData = {
        url: `${_urlAreas}/${id}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    return () => {
        return request(requestData)
            .then((res) => {
                return res && res.data
            })
    };
};
