import { BANKS } from "./constants";

export const ACCEPT_TYPES = ['.jpg', '.jpeg', '.png', '.svg']; // '.gif', '.bpm',

export const ACCEPT_IMAGE_SIZE = 10485760; // 10mb
export const YOUTUBE_REGEXP = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/

export const MAX_FLOORS_COUNT = 18

export const ACCEPT_BADGE_TYPES = {
    FOR_SALE: "600ab6e5e503271f1c8ebcd0",
    RESERVED: "6012c054b88dc494783275c5",
    SOLD: "6012c054b88dc494783275c6"
};

export const LIMIT_MIN_PRICE = 30000000
export const LIMIT_MAX_PRICE = 35000000

export const AHYM_PROGRAM = { //«Երիտասարդ ընտանիքին՝ մատչելի բնակարան»
    id: '60800da20a96c8177c9b0837',
    maxPrice: 30000000
}
export const LMC_PROGRAM = { //«Ազգային Հիփոթեքային Ընկերության ծրագիր»
    id: '61016487c59667735094f1de',
    maxPrice: 55000000,
    middlePrice: 45000000,
}

export const MORTGAGE_PROGRAMS = {
    [BANKS.INECOBANK]: {
        AHYF: { // «Երիտասարդ ընտանիքին՝ մատչելի բնակարան»
            id: "60800da20a96c8177c9b0837",
            advancePayment: 7.5,
            percent: 7.5,
            minMonths: 120,
            maxMonths: 144,
            maxPrice: 30000000,
            middlePrice: null,
            middlePercent: null,
            slug: "AHYF"
        },
        NMC: { // «Ազգային Հիփոթեքային Ընկերության ծրագիր»
            id: "61016487c59667735094f1de",
            advancePayment: 10,
            percent: 12.8,
            minMonths: 120,
            maxMonths: 180,
            maxPrice: 45000000,
            middlePrice: null,
            middlePercent: null,
            slug: "NMC"
        },
        BOF: { // «Բանկի սեփական միջոցներով»
            id: "64648af4592e9603f4a50b61",
            advancePayment: 10,
            percent: 13.5,
            minMonths: 36,
            maxMonths: 360,
            maxPrice: 80000000,
            middlePrice: null,
            middlePercent: null,
            slug: "BOF"
        },
    },
    [BANKS.EVOCABANK]: {
        AHYF: { // «Երիտասարդ ընտանիքին՝ մատչելի բնակարան»
            id: "60800da20a96c8177c9b0837",
            advancePayment: 7.5,
            percent: 7.5,
            minMonths: null,
            maxMonths: null,
            maxPrice: 30000000,
            middlePrice: null,
            middlePercent: null,
            slug: "AHYF"
        },
        NMC: { // «Ազգային Հիփոթեքային Ընկերության ծրագիր»
            id: "61016487c59667735094f1de",
            advancePayment: 10,
            percent: 12,
            minMonths: null,
            maxMonths: null,
            maxPrice: 55000000,
            middlePrice: 45000000,
            middlePercent: 14.5,
            slug: "NMC"
        },
        BOF: { // «Բանկի սեփական միջոցներով» (լողացող %) // BOF_FLOAT
            id: "63edeb8eb7fd7fdbb852db23",
            advancePayment: 10,
            percent: 13.2,
            minMonths: null,
            maxMonths: 240,
            maxPrice: null,
            middlePrice: null,
            middlePercent: null,
            slug: "BOF"
        },
        BOF_FIX: { // «Բանկի սեփական միջոցներով» (ֆիքսված %)
            id: "6102523bc59667735094f1e3",
            advancePayment: 10,
            percent: 14.5,
            minMonths: null,
            maxMonths: 180,
            maxPrice: null,
            middlePrice: null,
            middlePercent: null,
            slug: "BOF_FIX"
        },
    }


}
