import {_urlContact, _urlSubscribers, request} from "../api";
import {CONTACT_CONSTS, UTIL_CONSTS} from "../constants";

export const GetContact = () => {
    const requestData = {
        url: `${_urlContact}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: CONTACT_CONSTS.GET_CONTACT,
                    payload: data
                })
            })
    };
};

export const SendMessage = (data) => {
    const requestData = {
        url: `${_urlContact}`,
        method: "POST",
        data
    };
    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            dispatch({type: UTIL_CONSTS.END_LOADING})
        })
    }
};

export const AddSubscribers = (data) => {
    const requestData = {
        url: `${_urlSubscribers}`,
        method: "POST",
        data
    };
    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            dispatch({type: UTIL_CONSTS.END_LOADING})
        })
    }
};
