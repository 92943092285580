// import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

// Import assets
import "./calculator.scss"
import { DramIcon, EvocabankIcon, InecobankIcon, InfoIcon, LocationIcon } from "../../assets/images";

// Import Components
import { InputGroup } from "../../components/ui-elements/input-group/inputGroup";
import { PrimaryButton } from "../../components/ui-elements/buttons/Buttons";
import { Popover } from 'antd';


// Import utils
import { getPropsFromState } from "../../redux/mapStateToProps";
import { GetHouseById } from "../../redux/actions";
import { BANKS, HYPOTHEC_TERM_TYPES, PROJECTS_CONSTS, REPAYMENT_TYPES } from "../../constants/constants";
import { calculator, priceWithCommas } from "../../utils/helperFunctions";
import { getUrlWithLocal } from "../../utils/getUrlWithLocal";
import { MORTGAGE_PROGRAMS } from "../../constants/acceptedConsts";
import MapWindow from "../filtersPage/mapWindow/MapWindow";
import { toggleMapWindow } from "../../utils/toggleMapWindow";


class Calculator extends Component {
    constructor (props) {
        super(props);
        let bank = BANKS.INECOBANK;
        if (props.project) {
            if (props.project.slug === PROJECTS_CONSTS.Garun.name) {
                bank = BANKS.INECOBANK
            } else {
                bank = BANKS.EVOCABANK
            }
        }
        let { programsList, percent, program } = this.filterBanksListDependAmount({
            fromConstructor: true,
            setData: false,
            programsList: props?.banksList,
            hypothecAmount: props?.hypothecAmount,
            selectedBank: bank,
        })

        this.state = {
            selectedBank: bank,
            fieldsData: {
                hypothecAmount: props?.hypothecAmount ? props?.hypothecAmount : "",
                advancePayment: (props?.hypothecAmount && program?.advancePayment) ?
                    (props.hypothecAmount * program.advancePayment / 100) : "",
                monthsCount: (program?.maxMonths && Number.isInteger(program?.maxMonths / 12) ?
                    program?.maxMonths / 12 : 20) || "",
                termType: "YEAR",
                repaymentType: 'ANNUITY',
                percent: percent,
                programId: program?.id || undefined,
                program: program,
            },
            programsList: programsList || [],
            errors: {
                hypothecAmount: false,
                advancePayment: false,
                monthsCount: false,
                termType: false,
                repaymentType: false,
                programId: false,
                percent: false,
            },
            rightCardVisibility: false,
        };
        this.getInputValues = this.getInputValues.bind(this);
        this.getPriceValues = this.getPriceValues.bind(this);
        this.clearData = this.clearData.bind(this);
        this.calculate = this.calculate.bind(this);
        this.getSelectValue = this.getSelectValue.bind(this);
        this.getSelectValue = this.getSelectValue.bind(this);
        this.changeSelectedBank = this.changeSelectedBank.bind(this);
        this.totalData = {};
    }

    filterBanksListDependAmount ({
                                     fromConstructor = false,
                                     setData = true,
                                     _programsList = this.props.banksList,
                                     hypothecAmount = this.state?.fieldsData?.hypothecAmount,

                                     advancePayment = this.state?.fieldsData?.advancePayment,
                                     monthsCount = this.state?.fieldsData?.monthsCount,
                                     program_id = this.state?.fieldsData?.programId,
                                     selectedBank = this.state?.selectedBank,
                                 } = {}) {
        const defaultBank = selectedBank || BANKS.INECOBANK;// in constructor empty
        const programs = MORTGAGE_PROGRAMS[defaultBank];
        let availablePrograms = Object.values(programs);

        // console.log('availablePrograms', availablePrograms)
        // console.log('hypothecAmount', hypothecAmount)
        if (hypothecAmount) {
            availablePrograms = availablePrograms.filter(b => !b.maxPrice || hypothecAmount <= b.maxPrice)
        }
        let programsList = availablePrograms.map(pr => {
            return {
                ...pr,
                title: _programsList?.find(b => b.id === pr.id)?.title || ""
            }
        }) || [];
        // console.log('programsList', programsList, program_id)

        //==> V2
        let programId

        if (program_id && programsList?.find(p => p.id === program_id)) {
            programId = program_id;
        } else if (programsList?.find(p => p.id === programs.BOF.id)) {
            programId = programs.BOF.id;
        } else {
            programId = programsList?.[0]?.id
        }
        let program = programsList.find(item => item.id === programId)
        let percent = program?.percent;

        //<==
        if (program && hypothecAmount && program?.middlePrice && Number(hypothecAmount) >= program?.middlePrice) {
            percent = program?.middlePercent
        }
        if (setData) {
            if (!monthsCount) {
                monthsCount = program?.maxMonths ? program.maxMonths : 240;
                monthsCount = this.state.fieldsData.termType === 'YEAR' ? monthsCount / 12 : monthsCount
            } else {
                const months = this.state.fieldsData.termType === 'YEAR' ? monthsCount * 12 : monthsCount
                if (program?.maxMonths && months > program?.maxMonths) {
                    monthsCount = this.state.fieldsData.termType === 'YEAR' ? program?.maxMonths / 12 : program?.maxMonths
                }
            }
            this.setState({
                fieldsData: {
                    ...this.state?.fieldsData,
                    hypothecAmount: hypothecAmount,
                    advancePayment: advancePayment,
                    monthsCount: monthsCount ? monthsCount : program?.maxMonths,
                    percent: percent,
                    programId: programId,
                    program: program,
                },
                selectedBank: selectedBank,
                programsList: programsList || [],
            })
        }
        return {
            programsList,
            percent,
            program,
        }
    }

    componentDidMount () {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (prevProps?.hypothecAmount !== this.props?.hypothecAmount) {
            this.filterBanksListDependAmount({
                hypothecAmount: this.props?.hypothecAmount,
            })
        }
    }

    changeSelectedBank (bank) {
        this.filterBanksListDependAmount({
            setData: true,
            selectedBank: bank
        })
        this.setState({
            rightCardVisibility: false,
        })
    }

    getPriceValues (e) {
        let { name, value } = e.target
        let { program } = this.state.fieldsData
        value = value.replace(/\D/g, '')
        const hypothecAmount = name === "hypothecAmount" ? value : this.state.fieldsData.hypothecAmount
        let advancePayment = name === "advancePayment" ? value : this.state.fieldsData.advancePayment
        if (name === "hypothecAmount" && program) {
            advancePayment = value * program?.advancePayment / 100
            advancePayment < 1 && (advancePayment = '')
        }
        if (name === "advancePayment" && hypothecAmount && (Number(advancePayment) > Number(hypothecAmount))) {
            advancePayment = this.state.fieldsData.advancePayment
        }
        this.filterBanksListDependAmount({
            hypothecAmount,
            advancePayment: advancePayment && Math.trunc(advancePayment)
        })
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: false
            }
        })
    }

    getInputValues ({ name, value }) {
        const { program } = this.state.fieldsData
        if (name === 'monthsCount' && program?.maxMonths && value > program.maxMonths) {
            return;
        }
        const newState = {
            [name]: value
        }
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                ...newState,
            },
            errors: {
                ...this.state.errors,
                [name]: false
            }
        })
    }

    getSelectValue = (name, value) => {
        if (name === "programId" && value) {
            this.filterBanksListDependAmount({
                program_id: value
            })
        } else {
            const newState = {
                [name]: value
            }
            this.setState({
                fieldsData: {
                    ...this.state.fieldsData,
                    ...newState,
                },
                errors: {
                    ...this.state.errors,
                    [name]: false
                }
            })
        }
    }

    clearData () {
        this.filterBanksListDependAmount({
            hypothecAmount: '',
            advancePayment: '',
            monthsCount: '',
            program_id: ''
        })
        this.setState({
            errors: {
                hypothecAmount: false,
                advancePayment: false,
                monthsCount: false,
                termType: false,
                repaymentType: false,
                programId: false,
                percent: false,
            },
            rightCardVisibility: false
        },)
        this.props.calculate && this.props.calculate()
    }

    validate () {
        const { fieldsData } = this.state
        const { programId, program, ...fields } = fieldsData
        const { termType, monthsCount } = fields
        const errors = {};
        let result = true;
        Object.keys(fields).forEach(key => {

            if (!fields[key]) {
                errors[key] = true;
                result = false;
            }
        })
        if (fieldsData?.hypothecAmount - fieldsData?.advancePayment <= 0) {
            errors.advancePayment = true;
            result = false;
        }
        if (program?.percent && !program?.slug?.startsWith('BOF')
            &&  fieldsData?.percent < program?.percent) {
            errors.percent = true;
            result = false;
        }

        if (program?.advancePayment && fieldsData?.advancePayment < Math.trunc(fieldsData?.hypothecAmount * program?.advancePayment / 100)) {
            errors.advancePayment = true;
            result = false;
        }
        if (program && (program?.maxMonths || program?.minMonths)) {
            const months = termType === "YEAR" ? monthsCount * 12 : monthsCount;
            if (months < program?.minMonths || months > program?.maxMonths) {
                errors.monthsCount = true;
                result = false;
            }
        }

        this.setState({
            errors: errors
        })
        return result;
    }

    calculate () {
        const { hypothecAmount, advancePayment, monthsCount, termType, repaymentType, percent } = this.state.fieldsData
        if (this.validate()) {
            let newTerm = monthsCount
            let newHypothecAmount = advancePayment ? hypothecAmount - advancePayment : hypothecAmount
            let newRepaymentType = repaymentType === "ANNUITY" ? 0 : 1
            if (termType === "YEAR") {
                newTerm = Number(monthsCount) * 12
            }
            this.totalData = calculator(newHypothecAmount, percent, newTerm, newRepaymentType)
            this.setState({
                rightCardVisibility: true,
            })
            this.props.getCalculatorMonthlyPrice && this.props?.getCalculatorMonthlyPrice(this.totalData?.result?.totalPaymentInMonth)
        }
    }

    render () {
        const { fieldsData, errors, rightCardVisibility, programsList, selectedBank } = this.state
        const { contact, staticTexts, fromHouseDetails } = this.props

        const programOptions = !!programsList && programsList.map(item => ({
            id: item?.id,
            name: item?.title
        }));
        this.termTypeOptions = Object.keys(HYPOTHEC_TERM_TYPES).map(key => ({
            id: key,
            name: this.props?.staticTexts?.[HYPOTHEC_TERM_TYPES[key]]
        }));
        this.repaymentTypeOptions = Object.keys(REPAYMENT_TYPES).map(key => ({
            id: key,
            name: this.props?.staticTexts?.[REPAYMENT_TYPES[key]]
        }));

        const artGroupProject = [{
            latitude: contact?.location?.latitude,
            longitude: contact?.location?.longitude,
            title: contact?.address
        }]
        let monthsInfoContentMessage = ''
        let percentInfoContentMessage = ''
        if (fieldsData?.program) {
            if (fieldsData.program?.minMonths) {
                const min = fieldsData.termType === 'YEAR' ? fieldsData.program.minMonths / 12 : fieldsData.program.minMonths
                const max = fieldsData.termType === 'YEAR' ? fieldsData.program.maxMonths / 12 : fieldsData.program.maxMonths
                monthsInfoContentMessage = `${min} - ${max} ${this.props?.staticTexts?.[HYPOTHEC_TERM_TYPES[fieldsData.termType]]}`
            } else if (fieldsData?.program?.maxMonths) {
                const max = fieldsData.termType === 'YEAR' ? fieldsData.program.maxMonths / 12 : fieldsData.program.maxMonths
                monthsInfoContentMessage = `${this.props?.staticTexts?.calculator_month_info_prefix || ''} ${max} ${this.props?.staticTexts?.[HYPOTHEC_TERM_TYPES[fieldsData.termType]]}`
            }
            percentInfoContentMessage = (this.props?.staticTexts?.calculator_percent_info_prefix || "").replace('%s', fieldsData.program.percent)
        }


        return <div className={'CalcWrapper'}>
            <div className={'ContentWrapper'}>
                <div className={`HeaderWrapper ${fromHouseDetails ? "hidden" : ""}`}>
                    <div className={'header-left-wrapper'}>
                        <span className={'header-title'}>{staticTexts?.rout_item_calculator_title}</span>
                        <Breadcrumb>
                            <Breadcrumb.Item><Link
                                to={getUrlWithLocal('/home')}>{staticTexts?.rout_item_home}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link
                                to={getUrlWithLocal('/calculator')}>{staticTexts?.rout_item_calculator_text}</Link></Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div onClick={toggleMapWindow} className={'item-card'}>
                        <div className={'icon-wrapper'}>
                            <LocationIcon/>
                        </div>
                        <div className={'item-card-content'}>
                            <div className={'item-card-content-title'}>{staticTexts?.rout_item_address_title}</div>
                            <div className={'item-card-content-text'}>{contact?.address}</div>
                        </div>
                    </div>
                </div>
                <div className={"CalcMainContent"}>
                    <div className={'LeftSide'}>
                        <div className="calc-title">
                            <span>{staticTexts?.calculator_left_title}</span>
                            <div className={'bank-icons'}>
                                {(!this.props?.fromHouseDetails || this.props?.project?.slug === PROJECTS_CONSTS.Garun.name) &&
                                <div
                                    className={`bank-icon-wrapper ${!this.props?.fromHouseDetails && selectedBank === BANKS.INECOBANK ? 'selected' : ''}`}
                                    onClick={this.props?.fromHouseDetails ? undefined : this.changeSelectedBank.bind(this, BANKS.INECOBANK)}>
                                    <InecobankIcon className={"bank-icon"} title={""}/>
                                </div>}
                                {(!this.props?.fromHouseDetails || this.props?.project?.slug === PROJECTS_CONSTS.Ghapantsian.name) &&
                                <div
                                    className={`bank-icon-wrapper ${!this.props?.fromHouseDetails && selectedBank === BANKS.EVOCABANK ? 'selected' : ''}`}
                                    onClick={this.props?.fromHouseDetails ? undefined : this.changeSelectedBank.bind(this, BANKS.EVOCABANK)}>
                                    <EvocabankIcon className={"bank-icon"} title={""}/>
                                </div>}
                            </div>
                        </div>
                        <div className={'fields-horizontal'}>
                            <InputGroup
                                showSearch={false}
                                inputType={'select'}
                                value={fieldsData.programId}
                                name={'programId'}
                                error={errors.programId}
                                label={staticTexts?.calculator_bank_title_label}
                                // placeholder={staticTexts?.calculator_bank_title_placeholder}
                                onChange={this.getSelectValue}
                                options={programOptions}/>
                            <InputGroup
                                inputType={'input'}
                                type={"number"}
                                maxValue={24}
                                minValue={1}
                                name={"percent"}
                                value={fieldsData?.percent}
                                error={errors.percent}
                                label={staticTexts?.calculator_hypothec_percent_label}
                                // placeholder={staticTexts?.calculator_hypothec_percent_placeholder}
                                onChange={this.getInputValues}
                            >
                                {percentInfoContentMessage && <span className="icon">
                                    <Popover placement="bottom"
                                             content={<div className={'ant-popover-custom-content'}>
                                                 {percentInfoContentMessage}
                                             </div>}
                                    >
                                        <InfoIcon/>
                                    </Popover>

                                </span>}
                            </InputGroup>
                        </div>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={priceWithCommas(fieldsData.hypothecAmount)}
                            name={'hypothecAmount'}
                            error={errors.hypothecAmount}
                            minValue={1}
                            // disabled={fromHouseDetails}
                            label={staticTexts?.calculator_hypothec_price_label}
                            // placeholder={staticTexts?.calculator_hypothec_price_placeholder}
                            onChange={this.getPriceValues}/>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={priceWithCommas(fieldsData.advancePayment)}
                            name={'advancePayment'}
                            error={errors.advancePayment}
                            minValue={1}
                            label={staticTexts?.calculator_hypothec_advancePayment_label}
                            // placeholder={staticTexts?.calculator_hypothec_advancePayment_placeholder}
                            onChange={this.getPriceValues}/>
                        {/*<InputGroup*/}
                        {/*    showSearch={false}*/}
                        {/*    inputType={'select'}*/}
                        {/*    value={fieldsData.repaymentType}*/}
                        {/*    name={'repaymentType'}*/}
                        {/*    error={errors.repaymentType}*/}
                        {/*    label={staticTexts?.calculator_repayment_type_label}*/}
                        {/*    placeholder={staticTexts?.calculator_repayment_type_placeholder}*/}
                        {/*    onChange={this.getSelectValue}*/}
                        {/*    options={this.repaymentTypeOptions}/>*/}
                        <div className={'fields-horizontal'}>
                            <InputGroup
                                inputType={'input'}
                                type={"number"}
                                value={fieldsData?.monthsCount}
                                error={errors.monthsCount}
                                minValue={1}
                                name={'monthsCount'}
                                label={staticTexts?.calculator_hypothec_date_label}
                                // placeholder={staticTexts?.calculator_hypothec_date_placeholder}
                                onChange={this.getInputValues}
                            >
                                {monthsInfoContentMessage && <span className="icon">
                                    <Popover placement="bottom"
                                             content={<div className={'ant-popover-custom-content'}>
                                                 {monthsInfoContentMessage}
                                             </div>}
                                    >
                                        <InfoIcon/>
                                    </Popover>

                                </span>}
                            </InputGroup>
                            <InputGroup
                                showSearch={false}
                                inputType={'select'}
                                value={fieldsData.termType}
                                name={'termType'}
                                error={errors.termType}
                                label={staticTexts?.calculator_hypothec_type_label}
                                // placeholder={staticTexts?.calculator_hypothec_type_placeholder}
                                onChange={this.getSelectValue}
                                options={this.termTypeOptions}/>
                        </div>

                        <div className="btnsWrapper">
                            <PrimaryButton className={'dark'}
                                           cb={this.clearData}
                                           title={staticTexts?.calculator_cancel_btn}/>
                            <PrimaryButton
                                title={staticTexts?.calculator_calculate_btn}
                                cb={this.calculate}
                                disabled={fromHouseDetails && !fieldsData?.hypothecAmount}
                            />
                        </div>
                    </div>
                    {rightCardVisibility &&
                    <div className={'RightSide'}>
                        <div className="calc-title">
                            {staticTexts?.calculator_right_title}
                        </div>
                        <div className={'result-box'}>
                            {fieldsData?.repaymentType === "ANNUITY" && this.totalData?.result?.totalPaymentInMonth &&
                            <div className={'result-box-row'}>
                                <div
                                    className={'result-box-row-title'}>{staticTexts?.calculator_right_box_monthly_payment_title}</div>
                                <div
                                    className={'result-box-row-text'}>{priceWithCommas(this.totalData?.result?.totalPaymentInMonth)}<DramIcon/>
                                </div>
                            </div>}
                            <div className={'result-box-row'}>
                                <div
                                    className={'result-box-row-title'}>{`${staticTexts?.calculator_right_box_hypothec_date_title} (${fieldsData?.termType === "MONTH" ?
                                    staticTexts?.calculator_hypothec_type_mount : staticTexts?.calculator_hypothec_type_year})`}</div>
                                <div
                                    className={'result-box-row-text'}>{fieldsData?.monthsCount} {fieldsData?.termType === "MONTH" ?
                                    staticTexts?.calculator_hypothec_type_mount : staticTexts?.calculator_hypothec_type_year}</div>
                            </div>
                            <div className={'result-box-row'}>
                                <div
                                    className={'result-box-row-title'}>{staticTexts?.calculator_right_box_hypothec_percent_title}</div>
                                <div className={'result-box-row-text'}>{fieldsData?.percent} %</div>
                            </div>
                            {!!fieldsData?.hypothecAmount && <div className={'result-box-row'}>
                                <div
                                    className={'result-box-row-title'}>{staticTexts?.calculator_right_box_main_price_title}</div>
                                <div
                                    className={'result-box-row-text'}>{priceWithCommas(fieldsData?.hypothecAmount)}<DramIcon/>
                                </div>
                            </div>}
                            {!!fieldsData?.advancePayment && <div className={'result-box-row'}>
                                <div
                                    className={'result-box-row-title'}>{staticTexts?.calculator_right_box_advance_payment_title}</div>
                                <div
                                    className={'result-box-row-text'}>{priceWithCommas(fieldsData?.advancePayment)}<DramIcon/>
                                </div>
                            </div>}
                            <div className={'result-box-row'}>
                                <div
                                    className={'result-box-row-title'}>{staticTexts?.calculator_right_box_percent_title}</div>
                                <div
                                    className={'result-box-row-text'}>{priceWithCommas(this.totalData?.result?.totalPaymentPercent)}<DramIcon/>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                {rightCardVisibility && !!this.totalData?.monthsResult?.length && <div className="results-table">
                    <table>
                        <thead>
                        <tr>
                            <th>{staticTexts?.calculator_table_column_month}</th>
                            <th>{staticTexts?.calculator_table_column_percent}</th>
                            <th>{staticTexts?.calculator_table_main_amount}</th>
                            <th>{staticTexts?.calculator_table_monthly_payment}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.totalData?.monthsResult.map((month, index) => {
                            return <tr key={index}>
                                <td>{index + 1}</td>
                                {!!month?.length && month.map((item, i) => {
                                    return <td key={i}>
                                        {priceWithCommas(item)}
                                    </td>
                                })}
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>}
            </div>
            {contact && <MapWindow projects={artGroupProject}/>}
        </div>
    }
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'contact',
    'banksList',
]);

const mapDispatchToProps = {
    GetHouseById
};

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);
