//----------------------------------4Steps-server--------------------------------------------------

// const ADMIN_URL ="http://173.212.233.220:6001/api/v2";
// const BASE_URL =  "http://173.212.233.220:6002/api/v2";
// const HOST_MEDIA_URL = "http://173.212.233.220:6001";

//-----------------------------------ART-GROUP - server--------------------------------------------------
//
// const ADMIN_URL ="https://app.art-group.am/admin/api/v2";
// const BASE_URL =  "https://app.art-group.am/front/api/v2";
// const HOST_MEDIA_URL = "https://app.art-group.am/admin";

const ADMIN_URL ="https://app.artresidence.am/admin/api/v2";
const BASE_URL =  "https://app.artresidence.am/front/api/v2";
const HOST_MEDIA_URL = "https://app.artresidence.am/admin";



export const _hostMedia = HOST_MEDIA_URL;
export const _urlLanguage = BASE_URL + "/languages";
export const _urlStaticTexts = ADMIN_URL + "/static-texts";
export const _urlMenu = BASE_URL + "/menu";
export const _urlBadges = BASE_URL + "/badges";
export const _urlBanks = BASE_URL + "/banks";
export const _urlCustomPages = BASE_URL + "/custom-pages";
export const _urlSlider = BASE_URL + "/slider";
export const _urlAbout = BASE_URL + "/about";
export const _urlContact = BASE_URL + "/contact";
export const _urlBuildings = BASE_URL + "/buildings";
export const _urlFloors = BASE_URL + "/floors";
export const _urlHouses = BASE_URL + "/houses";
export const _urlParking = BASE_URL + "/parking";
export const _urlReservation = BASE_URL + "/reservation";
export const _urlSubscribers = BASE_URL + "/subscribers";
export const _urlAboutSlider = BASE_URL + "/about-slider";
export const _urlProjects = BASE_URL + "/projects";
export const _urlAreas = BASE_URL + "/areas";
export const _urlCallRequest = BASE_URL + "/call-request";
export const _urlPartners = BASE_URL + "/partners";

