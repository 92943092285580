// import packages
import React from "react";
import {connect} from 'react-redux';
import {history} from "../../configs/history";
import {Link} from "react-router-dom";

//import styles
import "./fullscreenMenu.scss";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {ChangeLanguage} from "../../redux/actions";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";


function FullscreenMenu(props) {
    const {contact, staticTexts, selectedLanguage, languages, openMenu, toggleMenu, projects} = props;

    const phone = contact?.phoneNumber?.replace(/\D/g, '').substring(0, 12)
    const areaCode = phone?.substring(0, 3);
    const code = phone?.substring(3, 5);
    const content = phone?.substring(5, 12);

    function navigate(path) {
        history.push(getUrlWithLocal(`${path}`))
        toggleMenu()
    }

    return <div className={`menuWrapper ${openMenu ? 'opened' : ''}`}>
        <div className="menuWrapper__main">
            <div className="menuWrapper__main__content">
                <div className="menuWrapper__main__content__list">
                    <div onClick={()=> navigate(`/about`)} className="menu-item">{staticTexts?.menu_about}</div>
                    <div onClick={()=> navigate(`/contact`)} className="menu-item">{staticTexts?.menu_contact}</div>
                    <div onClick={()=> navigate(`/filter`)} className="menu-item">{staticTexts?.menu_apartments}</div>
                    <div onClick={()=> navigate(`/calculator`)} className="menu-item">{staticTexts?.menu_calculator}</div>
                </div>
                <div className="menuWrapper__main__content__list">
                    {
                        !!projects?.length && projects?.map(item => {
                            return <div onClick={()=> navigate(`/buildings/${item?.slug}`)} className="menu-item" key={item.id}>
                                {item?.title}
                            </div>
                        })
                    }
                </div>
            </div>
            <div className="menuWrapper__main__languages">
                {!!languages?.length && languages?.map((item) => {
                    return <div className={`language ${selectedLanguage?.code === item.code ? 'active' : ''}`}
                                key={item.id}
                                onClick={() => {
                                    history.push(`/${item.code}${history.location.pathname.slice(3)}`)
                                    props.ChangeLanguage(item)
                                }}>
                        {item.name}
                    </div>
                })
                }
            </div>
        </div>
        <div className="menuWrapper__footer">
            <div className="menuWrapper__footer__info">
                <span className="copyright">{staticTexts?.footer_section_copyright_text}</span>
                <span className="phone">{`+${areaCode} (${code}) ${content}`}</span>
                <span className="line"/>
                <a className="email" href={`mailto:${contact?.email}`}>{contact?.email}</a>
            </div>
            <div className="menuWrapper__footer__social">
                <a href={contact?.facebookUrl} target={'_blank'}>Facebook</a>
                <a href={contact?.instagramUrl} target={'_blank'}>Instagram</a>
                <a href={contact?.linkedinUrl} target={'_blank'}>Linkedin</a>
            </div>
        </div>
    </div>

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contact',
        'languages',
        'projects',
        'selectedLanguage'
    ])
};

const mapDispatchToProps = {
    ChangeLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(FullscreenMenu);
