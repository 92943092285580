// import packages
import React, {useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

// Import assets
import "./project.scss"
import {LocationIcon} from "../../../../assets/images";

// Import Components
import {PrimaryButton} from "../../../../components/ui-elements/buttons/Buttons";

// Import utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {generateImageMediaUrl, generateSliderMediaUrl} from "../../../../utils/generateMediaUrl";
import {getUrlWithLocal} from "../../../../utils/getUrlWithLocal";
import ProgressiveImage from "react-progressive-graceful-image";
import {resizeImage} from "../../../../utils/resizeImage";
import { toggleMapWindow } from "../../../../utils/toggleMapWindow";


function Project({project, reverse, staticTexts}) {


    return <div className={`AboutProject`}>
        <span className="blur-circle"/>
        <span className="blur-circle right"/>
        <div className={`project-content ${reverse ? 'reverse' : ''}`}>
            <div className="project-info">
                <div className="project-info__title">{project?.title}</div>
                <div className="project-info__description">
                    {project?.description}
                </div>
                <div className="project-info__address" onClick={toggleMapWindow}>
                    <LocationIcon/>
                    {project?.address}
                </div>
                <div className="project-info__question">
                    <PrimaryButton title={staticTexts?.homepage_projects_about_btn}
                                   link={getUrlWithLocal(`/buildings/${project?.slug}`)}/>
                    <div className="link">
                        {staticTexts?.homepage_projects_question}&nbsp;
                        <Link to={{
                            pathname: getUrlWithLocal("/filter"),
                            state: {projects: project?.id}
                        }}>{staticTexts?.homepage_projects_question_link}</Link>
                    </div>
                </div>
            </div>
            <Link to={getUrlWithLocal(`/buildings/${project?.slug}`)} className="project-link">
                <div className="project-img-wrapper">
                    <ProgressiveImage src={generateSliderMediaUrl(project?.mediaSecondary?.path)}
                                      placeholder={resizeImage(generateImageMediaUrl(project?.mediaSecondary?.path))}>
                        {(src, loading) => (
                            <img
                                className={`project-img image${loading ? " loading" : " loaded"}`}
                                src={src}
                                alt=""
                                width="300"
                                height="300"
                            />
                        )}
                    </ProgressiveImage>

                    <div className="overlay-wrapper"/>
                    <span className="overlay-title">{project?.title}</span>
                </div>
            </Link>
            <div className="project-info__mobile_title">{project?.title}</div>
            {/*<img src={generateFileMediaUrl(project?.mediaSecondary?.path)}*/}
            {/*     className={'project-img'} alt={"about"}/>*/}
        </div>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'projects',
    'contact'
]);

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Project);
