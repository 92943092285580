// import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Slider from "react-slick";
import {Link} from 'react-router-dom'

// Import assets
import "./about.scss"
import {ArrowLeftIcon, ArrowRightIcon} from "../../assets/images";

// Import Components
import {PrimaryButton} from "../../components/ui-elements/buttons/Buttons";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {generateFileMediaUrl} from "../../utils/generateMediaUrl";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";


class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStorage: {},
            slideIndex: 0
        }
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    nextSlide = (index) => {
        const {aboutSlider} = this.props
        this.setState({
            slideIndex: this.state.slideIndex - 1 === index ? 0 : index + 1,
        });
        if (aboutSlider.mediaMain?.length - 1 === this.state.slideIndex) {
            this.setState({
                slideIndex: this.state.slideIndex,
            });
        }
        this.slider.slickNext();
    };

    prevSlide = (index) => {
        this.setState({
            slideIndex: index === 0 ? 0 : index - 1,
        });
        this.slider.slickPrev();
    };


    render() {
        const {sliderData, slideIndex} = this.state
        const {staticTexts, aboutSlider, about} = this.props
        const settingsImages = {
            dots: false,
            arrows: false,
            ref: slider => (this.slider = slider),
            swipeToSlide: false,
            focusOnSelect: false,
            infinite: false,
            slidesToShow: 5,
        };
        const AboutBottomBlockData = about?.blocks?.find(item => item?.slug === "block-about")


        return <div className={'AboutWrapper'}
                    style={{backgroundImage: `url(${generateFileMediaUrl(about?.backgroundImage?.path)})`}}>
            <div className="CountsWrapper">
                <div className={'counts-bg'}>
                    <h1 className={'about-main-title'}>{about?.pageName}</h1>
                    {/*<div className={'counts-main-content'}>*/}
                    {/*    <div className={'counts-item'}>*/}
                    {/*        <div className={'count'}>{about?.projectsCount ? about?.projectsCount : 0}</div>*/}
                    {/*        <div className={'count-title'}>{staticTexts?.about_page_count_projects}</div>*/}
                    {/*    </div>*/}
                    {/*    <div className={'line'}/>*/}
                    {/*    <div className={'counts-item'}>*/}
                    {/*        <div*/}
                    {/*            className={'count'}>{about?.commercialsCount ? about?.commercialsCount : 0}</div>*/}
                    {/*        <div className={'count-title'}>{staticTexts?.about_page_count_commercials}</div>*/}
                    {/*    </div>*/}
                    {/*    <div className={'line'}/>*/}
                    {/*    <div className={'counts-item'}>*/}
                    {/*        <div className={'count'}>{about?.apartmentsCount ? about?.apartmentsCount : 0}</div>*/}
                    {/*        <div className={'count-title'}>{staticTexts?.about_page_count_houses}</div>*/}
                    {/*    </div>*/}
                    {/*    <div className={'line'}/>*/}
                    {/*    <div className={'counts-item'}>*/}
                    {/*        <div className={'count countup'}>{about?.workersCount ? about?.workersCount : 0}</div>*/}
                    {/*        <div className={'count-title'}>{staticTexts?.about_page_count_workers}</div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className={'AboutMainContent'}>
                <div className={'AboutTextContent'}>
                    <div className="slider-wrapper">
                        <div className="main-img-wrapper">
                            <div className="main-img-content">
                                <img src={generateFileMediaUrl(aboutSlider?.[slideIndex]?.mediaMain?.path)}
                                     className={'main-img'}/>
                            </div>
                        </div>

                        <div className="images-list-wrapper">
                            {!!aboutSlider?.length && <Slider {...settingsImages}>
                                {aboutSlider?.map((item, index) => {
                                    return <div key={item.id}
                                                className={`list-item ${(slideIndex === index) ? 'active' : ''}`}>
                                        <img
                                            src={generateFileMediaUrl(item?.mediaMain?.path)} className={'list-img'}
                                            onClick={() => this.setState({slideIndex: index})} alt={""}/>
                                    </div>
                                })}
                            </Slider>}
                            {aboutSlider?.length > 5 &&
                            <>
                                <button className={`slider-btn  prev-btn `}
                                        onClick={() => this.prevSlide(slideIndex)}
                                        disabled={slideIndex === 0}
                                >
                                    <ArrowLeftIcon/>
                                </button>
                                <button
                                    className={`slider-btn next-btn `}
                                    disabled={slideIndex === aboutSlider?.length - 1}
                                    onClick={() => this.nextSlide(slideIndex)}>
                                    <ArrowRightIcon/>
                                </button>
                            </>
                            }
                        </div>
                    </div>
                    <div className="TextWrapper">
                        <h2 className={'wrapper-title'}>{about.title}</h2>
                        <div className={'description'} dangerouslySetInnerHTML={{__html: about.description}}/>
                        <div className="btnsWrapper">
                            <PrimaryButton className={'dark'}
                                           title={staticTexts?.about_page_btn_contact}
                                           link={getUrlWithLocal('/contact')}/>
                            <PrimaryButton link={getUrlWithLocal("/filter")}
                                           title={staticTexts?.about_page_btn_houses}/>
                        </div>
                    </div>
                </div>
                {/*<div className={'features-list'}>*/}
                {/*    {about?.features && about?.features?.length && about.features.map(item => {*/}
                {/*        return <Link to={getUrlWithLocal('/about')} key={item.id}*/}
                {/*                    className={'features-item'}>*/}
                {/*            <img src={generateFileMediaUrl(item?.mediaMain?.path)} alt={""}/>*/}
                {/*            <span>{item?.title}</span>*/}
                {/*        </Link>*/}
                {/*    })}*/}
                {/*</div>*/}
                <div className={'AboutTextContent'}>
                    <div className="TextWrapper LeftTextWrapper">
                        <h2 className={'wrapper-title'}>{AboutBottomBlockData?.title}</h2>
                        <div className={'description'} dangerouslySetInnerHTML={{__html: AboutBottomBlockData?.description}}/>
                    </div>
                    {/*<div className="slider-wrapper LeftImgWrapper">
                        <div className="main-img-wrapper">
                            <div className="main-img-content">
                                <img src={generateFileMediaUrl(AboutBottomBlockData?.mediaMain?.path)}
                                     className={'main-img'}/>
                            </div>
                        </div>
                    </div>*/}
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'aboutSlider',
    'about'
]);

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(About);
