// import packages
import React, {useState} from "react";
import {connect} from "react-redux";
import {LoadingOutlined} from '@ant-design/icons'
import {isEmail} from "validator";

// Import assets
import "./contact.scss"
import {TickIcon} from "../../../assets/images";

// Import Components
import {InputGroup} from "../../../components/ui-elements/input-group/inputGroup";

// Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {AddSubscribers} from "../../../redux/actions";


function Contact(props) {
    const [tick, setTick] = useState(false)
    const [email, setEmail] = useState('')
    const [errorEmail, setErrorEmail] = useState(false)
    const showTick = () => {
        setTimeout(
            () => {
                setTick(false)
                setEmail("")
                setErrorEmail(false)
            },
            2000,
        )

    }

    function getInputValues(e) {
        setErrorEmail(false)
        const {name, value} = e.target;
        setEmail(value)
    }

    function addSubscribers() {
        if (email && isEmail(email)) {
            props.AddSubscribers({email: email}).then(() => {
                setEmail(email)
                setTick(true)
                showTick()
            }, errorEmail => setErrorEmail(!errorEmail))
        } else {
            setErrorEmail(true)
        }
    }

    const {requestLoading, staticTexts} = props

    return <div className={'HomepageContact'}>
        <div className="LeftSide">
            <h1 className={'title'}>{staticTexts?.homepage_contact_title}</h1>
            <div className={'description'}>{staticTexts?.homepage_contact_text}
            </div>
        </div>
        <div className="RightSide">
            <InputGroup
                inputType={'input'}
                type={"text"}
                value={email}
                error={errorEmail}
                name={'email'}
                placeholder={staticTexts?.homepage_contact_placeholder_email}
                maxLength={100}
                onChange={getInputValues}
            />
            <button className='PrimaryButton bordered'
                    onClick={() => {
                        if (!requestLoading && addSubscribers) {
                            addSubscribers();
                        }
                    }}
            >
                {staticTexts?.homepage_contact_button_send}
                {
                    requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                        : <TickIcon title={''} className={`tick-icon ${tick ? 'show-tick' : 'hide-tick'}`}/>
                }

            </button>
        </div>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'requestLoading'
]);

const mapDispatchToProps = {
    AddSubscribers
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
