export const GENERAL_CONSTS = {
    GET_LANGUAGES: "GET_LANGUAGES",
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
    GET_STATIC_TEXTS: "GET_STATIC_TEXTS",
    GET_MENU: "GET_MENU",
    GET_BADGES: "GET_BADGES",
    GET_CUSTOM_PAGES: "GET_CUSTOM_PAGES",
    GET_BANKS: "GET_BANKS",
    /*-------------Projects----------------*/
    GET_PROJECTS: "GET_PROJECTS",
    GET_PROJECT_BY_ID: "GET_PROJECT_BY_ID",
    CLEAR_PROJECTS_BY_ID: "CLEAR_PROJECTS_BY_ID",

    GET_AREAS: "GET_AREAS",
    GET_AREAS_INFO: "GET_AREAS_INFO",
    GET_PARTNERS: "GET_PARTNERS",
};
