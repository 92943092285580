import  {useState, useEffect} from "react";

export const useImageError = () => {
    const [el, setEl] = useState(null); // contains image reference
    const [error, setError] = useState(false); // contains error flag

    const _handleError = () => {
        setError(true);
    }  // set error
    const retry = () => {
        setError(false);
    } // set error false to img can re-render

    useEffect(() => {
        // use of error event of the image tag
        el?.addEventListener("error", _handleError);

        return () => {
            el?.removeEventListener("error", _handleError);
        }
    }, [el]);

    return [
        setEl, // set the image ref
        error, // error flag
        retry, // a func, which can be used to re-render image
        el // img ref(for special case which requires ref)
    ];
};

export const calculator = function (tbccAmount, tbccPercent, tbccTerms, tbccType) {

    let values = {
        tbccAmount: null,
        tbccPercent: null,
        tbccTerms: null,
        tbccType: null
    };
    values.tbccAmount = parseInt(tbccAmount);
    values.tbccPercent = parseFloat(tbccPercent);
    values.tbccTerms = parseInt(tbccTerms);
    values.tbccType = parseInt(tbccType);
    if (values.tbccType === 0) {
        let tableResultsTbody = {};
        tableResultsTbody.monthsResult = [];
        let previousBalance = values.tbccAmount;
        let paymentTotal = (values.tbccAmount * (values.tbccPercent / 100) / 12) / (1 - (1 / (Math.pow((1 + (values.tbccPercent / 100) / 12), values.tbccTerms))));
        let totalPaymentBalance = 0;
        let totalPaymentPercent = 0;
        for (let i = 0; i < values.tbccTerms; i++) {
            let paymentPercent = ((previousBalance * (values.tbccPercent / 100)) / 12);
            let paymentBalance = (paymentTotal - paymentPercent);
            previousBalance = (previousBalance - paymentBalance);
            if (previousBalance < 0) {
                previousBalance = 0;
            }
            totalPaymentBalance = totalPaymentBalance + paymentBalance;
            totalPaymentPercent = totalPaymentPercent + paymentPercent;
            tableResultsTbody["monthsResult"][i] = [paymentPercent.toFixed(0), paymentBalance.toFixed(0), paymentTotal.toFixed(0)]
        }
        tableResultsTbody["result"] = {
            totalPaymentPercent: totalPaymentPercent.toFixed(0),
            totalPaymentBalance: totalPaymentBalance.toFixed(0),
            totalPayment: (totalPaymentBalance + totalPaymentPercent).toFixed(0),
            totalPaymentInMonth: paymentTotal.toFixed(0)

    }
        return tableResultsTbody
    }

    if (values.tbccType === 1) {
        let tableResultsTbody = {};
        tableResultsTbody.monthsResult = [];
        let previousBalance = values.tbccAmount;
        let paymentBalance = values.tbccAmount / values.tbccTerms;
        let totalPaymentBalance = 0;
        let totalPaymentPercent = 0;
        for (let i = 0; i < values.tbccTerms; i++) {
            let paymentPercent = previousBalance * ((values.tbccPercent / 100) / 12);
            let paymentTotal = paymentPercent + paymentBalance;
            previousBalance = previousBalance - paymentBalance;
            totalPaymentBalance = totalPaymentBalance + paymentBalance;
            totalPaymentPercent = totalPaymentPercent + paymentPercent;
            tableResultsTbody["monthsResult"][i] = [paymentPercent, paymentBalance, paymentTotal];
        }

        tableResultsTbody["result"] = {
            totalPaymentPercent: totalPaymentPercent.toFixed(0),
            totalPaymentBalance: totalPaymentBalance.toFixed(0),
            totalPayment: (totalPaymentBalance + totalPaymentPercent).toFixed(0),
        }
        return tableResultsTbody
    }
}

export const priceWithCommas = (price) => {
    return price?.toString()?.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ',');
}
