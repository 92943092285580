export const AREA_TYPES = {
    APARTMENT: {
        title: 'area_type_apartment',
        key: 'APARTMENT',
    },
    OFFICE: {
        title: 'area_type_office',
        key: 'OFFICE',
    },
  /*  HOTEL:{
        title: 'area_type_hotel',
        key: 'HOTEL',
    },*/
    PARKING:{
        title: 'area_type_perking',
        key: 'PARKING',
    },
};