// Import packages
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

// Import styles
import "./apartments-card.scss";

import {UploadIcon, ViewedIcon} from "../../../assets/images";
// Import Components
import ReserveModal from "../../modal/reserveModal/ReserveModal";
import {Dropdown, Menu} from "antd";
import {PrimaryButton} from "../../ui-elements/buttons/Buttons";

// Import utils
import {generateFileMediaUrl, generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {HOUSE_BADGES} from "../../../constants/constants";
import {priceWithCommas} from "../../../utils/helperFunctions";
import {resizeImage} from "../../../utils/resizeImage";
import {getUrlWithLocal} from "../../../utils/getUrlWithLocal";
import {ViewHouses} from "../../../redux/actions";


function ApartmentsCard(props) {
    const [visible, setVisible] = useState(false);
    const [toggled, setToggled] = useState(false);
    const {house, staticTexts} = props

    useEffect(() => {
    }, [props.viewedHouses])
    const toggleVisibility = () => {
        setVisible(!visible)
    }

    function preventClick(e) {
        e.preventDefault();
        e.stopPropagation()
    }

    function downloadFile(id) {
        const pdf = house?.pdfs?.find(item => item?.id === id)
        if (pdf) {
            fetch(generateFileMediaUrl(pdf?.path))
                .then(response => {
                    response.blob().then(blob => {
                        let a = document.createElement('a');
                        a.href = window.URL.createObjectURL(blob);
                        a.download = pdf?.name;
                        a.click();
                    });
                });
        }
    }

    function toggleCard() {
        props.ViewHouses(house.id)
        setToggled(!toggled)
    }

    const menuPdf = !!house?.pdfs?.length && (
        <Menu className="pdfs-dropdown">
            {house?.pdfs?.map((item) => {
                return <Menu.Item key={item.id}
                                  onClick={() => downloadFile(item?.id)}
                                  className={'pdf-dropdown-item'}>
                    <div>{item.name}</div>
                </Menu.Item>
            })
            }
        </Menu>
    );

    return <div onClick={toggleCard}>
        <Link
            to={getUrlWithLocal(`/building/${house?.building}/floor/${house?.floor?.number}/house/${house?.number}`)}
            className={'apartments-card'}
            target={'_blank'}>
            <div className={'apartments-card__wrapper'}>
                <img src={resizeImage(generateImageMediaUrl(house?.mediaMain?.path), 210, 200)} alt=""/>
            </div>
            {props?.viewedHouses?.includes(house?.id) && <div className="apartments-card__viewed">
                <ViewedIcon/>
                <span>{staticTexts?.mouse_info_viewed}</span>
            </div>}
            <div className={'apartments-card__content'}>
                <div className={'apartments-card__content__number'}>N {house.areaNumber}</div>

                <div className={'apartments-card__content__item'}>
                    <div className={'item-name'}>{staticTexts?.house_card_area_title}</div>
                    <div className={'line'}/>
                    <div className={'item-area'}>{`${house?.area} ${staticTexts?.measurement_unit_meter}`}<sup>2</sup>
                    </div>
                </div>
                <div className={'apartments-card__content__item'}>
                    <div className={'item-name'}>{staticTexts?.house_card_floor_title}</div>
                    <div className={'line'}/>
                    <div className={'item-area'}>{house?.floor?.number}</div>
                </div>
                <div className={'apartments-card__content__item'}>
                    <div className={'item-name'}>{staticTexts?.house_card_rooms_title}</div>
                    <div className={'line'}/>
                    <div className={'item-area'}>{house?.roomsCount || "-"}</div>
                </div>
            </div>
            <div className={'apartments-card__actions'}>
                <div className={'is-reserve'} style={{color: house?.badge.color}}>
                    <span>{house?.badge.title}</span>
                </div>
                <div className={'card-price'}>{staticTexts?.filter_price_title}։&nbsp; {
                    house?.price ? <>{priceWithCommas(house?.price)}&nbsp;֏</>
                        : staticTexts?.house_card_price_contractual
                }</div>

                <div className={'card-btns-wrapper'} onClick={preventClick}>
                    <PrimaryButton disabled={house?.badge?.id !== HOUSE_BADGES?.available?.key}
                                   title={staticTexts?.house_card_reverse_btn}
                                   cb={toggleVisibility}/>
                    <Dropdown overlay={menuPdf}
                              trigger={['click']}
                              overlayClassName={'pdfs-wrapper'}
                              placement="bottomRight"
                              size={'small'}>
                        <button className={'PrimaryButton upload-btn'}><UploadIcon/></button>
                    </Dropdown>
                    <ReserveModal staticTexts={staticTexts} area={house} visible={visible}
                                  toggleVisibility={toggleVisibility}/>
                </div>
            </div>
        </Link>
    </div>
}


const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'viewedHouses',
]);

const mapDispatchToProps = {ViewHouses};

export default connect(mapStateToProps, mapDispatchToProps)(ApartmentsCard);

