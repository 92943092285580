// import packages
import React, { useState } from "react";

import { YMaps, Map, Placemark } from 'react-yandex-maps';

// Import assets
import "./filterMap.scss"
import { PlusIcon } from "../../../assets/images";

//Utils
import { generateFileMediaUrl } from "../../../utils/generateMediaUrl";
import { history } from "../../../configs/history";
import { getUrlWithLocal } from "../../../utils/getUrlWithLocal";
import { toggleMapWindow } from "../../../utils/toggleMapWindow";

function FilterMap ({ focusedProject,  projects, setMapRendered }) {
    const [ymap, setYmap] = useState(null)
    const mapCenter = focusedProject ?
        [focusedProject?.latitude, focusedProject?.longitude] :
        [40.214033, 44.552007] //default center.

    const handleClick = (item) => {
        history.push(getUrlWithLocal(`/buildings/${item?.slug}`))
    };

    function createChipsLayout (item) {
        const src = generateFileMediaUrl(item?.mediaMain?.path)

        const MyLayoutClass = ymap?.templateLayoutFactory.createClass(
            '<div class="icon-content">' +
            '<div class="marker"><img class="marker-img" src=' + src + ' alt=""></div>' +
            '<div class="marker-title">' + item?.title + '</div>' +
            '</div>', {
                // Define the methods for handling the click and mouseover events
                build: function() {
                    MyLayoutClass.superclass.build.call(this);
                    var map = this.getData().geoObject.getMap();

                    let options = this.getData().options;
                    let circleShape = { type: 'Circle', coordinates: [0, -75], radius: 41, fillColor: "#00FF00",  };
                    let rectangleShape = { type: 'Rectangle', coordinates: [[-85, -45], [85, -22]] };
                    options.set('shape', circleShape);
                },

            }
        );
        return MyLayoutClass
    }

    return <div className="map">
        <button className="map__btn" onClick={toggleMapWindow}><PlusIcon/></button>

        <YMaps query={{
            apikey: 'eacc125a-6e5b-4983-a845-721586b10f68',
            // lang: `${selectedLanguage?.code}_${selectedLanguage?.code?.toUpperCase()}`
        }}
        >
            <Map
                state={{
                    center: mapCenter,
                    zoom: 12,
                    hue: "ff0000",
                }}
                onLoad={(ympasInstance) => {
                    setYmap(ympasInstance)
                    setMapRendered(true)
                }}
                options={{
                    minZoom: 5
                }}
                modules={["Placemark", "geocode", "geoObject.addon.balloon", "templateLayoutFactory", "layout.ImageWithContent"]}
                width={'100%'} height={'100%'}
            >
                {
                    !!projects?.length && projects?.map(item => {
                        return <Placemark
                            onClick={handleClick.bind(null, item)}
                            geometry={[item?.latitude, item?.longitude]}
                            // options={{
                            //     iconLayout: circleLayout,
                            // }}
                            options={{
                                iconLayout: createChipsLayout(item)
                            }}
                            key={item?.id}
                        />
                    })
                }
            </Map>
        </YMaps>

    </div>
}

export default FilterMap;
